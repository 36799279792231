import React, { useEffect } from "react";

const TermsCondition = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <main className="text-white md:pt-[60px] sm:pt-[40px]">
        <section className="h-[50vh] bg-no-repeat w-full bg-[url('../../assets/img/icon/intro.webp')]">
          <div className="container mx-auto px-4 h-full flex justify-center items-center">
            <h1 className="lg:text-6xl sm:text-4xl text-3xl">
              Terms & Conditions
            </h1>
          </div>
        </section>
        <div className="container px-4 mx-auto">
          <div className="lg:max-w-6xl mx-auto py-20 px-5">
            <h4 className="sm:text-3xl text-2xl lg:text-4xl text-red-500 pt-16">
              Shipping & Delivery
            </h4>
            <p className="lg:text-lg  pt-8 font-thin tracking-wide opacity-60">
              Orders are usually dispatched within 72 hours of order
              confirmation by the customer and are usually delivered within the
              time frame stated on each product's page. Open deliveries of
              parcels are not allowed. Customer can only open and inspect the
              product after paying the total amount and receiving the product
              from the delivery personnel.
            </p>
            <h4 className="sm:text-3xl text-2xl lg:text-4xl text-red-500 pt-16">
              Privacy & Security
            </h4>
            <p className="lg:text-lg  pt-8 font-thin tracking-wide opacity-60">
              It is committed to protecting and managing the personal
              information you share with us, and we understand the importance of
              doing so. The phrase "personal information" means any information
              by which you can be identified, such as your name, mailing
              address, telephone number, etc. The purpose of this privacy policy
              is to inform you that any personal information we gather from you,
              such as your phone number and email address, will never be shared
              with a third party and will only be used to send you order
              updates, offers, and promotional SMS/emails. You may opt-out from
              the same by simply changing your communication preferences from my
              account page.
            </p>
            <h4 className="sm:text-3xl text-2xl lg:text-4xl text-red-500 pt-16">
              Returns, Replacements & Cancellations
            </h4>
            <p className="lg:text-lg  pt-8 font-thin tracking-wide opacity-60">
              Products may only be returned or replaced if they are eligible as
              per the information given below each product's page. Return &
              exchange facility can only be availed within 96 hours of the goods
              being delivered to the shipping location. Dopegolf.com will not be
              liable for any errors after the 96 hour period is exhausted.{" "}
            </p>
            <p className="lg:text-lg  pt-8 font-thin tracking-wide opacity-60">
              Return pickup can be only being arranged for pin codes covered by
              our return courier partners. Once your return is received at our
              fulfilment centre, you will receive a replacement against the
              returned product or a full refund in the form of store credit or a
              gift card, which can be redeemed during a future purchase at
              Dopegolf.com. Any outbound shipping charges paid will not be
              refunded if the order is self-returned by the customer.
            </p>
            <p className="lg:text-lg  pt-8 font-thin tracking-wide opacity-60">
              Please follow these directions to replace the product using our
              pickup service:
            </p>
            <ul className="lg:text-lg pl-10 list-disc  font-thin tracking-wide opacity-60">
              <li className="pt-7 ">
                Go to 'My Account' Section of the website and select the return
                or replace option for your current order if you are an existing
                customer.
              </li>
              <li className="pt-7 ">
                Fill in the necessary details and wait for dope golf to provide
                additional information.
              </li>
              <li className="pt-7 ">
                In case you have a guest account. Kindly contact our customer
                service team to arrange a manual pickup by writing an email to
                contact@dopegolf.com.Customer service will arrange a pickup
                after the request is received.
              </li>
              <li className="pt-7 ">
                If our return pickup courier partner does not cover your pin
                code, you need to self-return the product back to our warehouse
                by your preferred courier.
              </li>
              <li className="pt-7 ">
                Include all original packing materials, manuals and accessories
                that came with the product. The product and its packaging should
                be in resaleable and brand new condition.
              </li>
              <li className="pt-7 ">
                Once the goods reach our fulfilment centre and pass our quality
                check, a refund or replacement will be issued.
              </li>
              <li className="pt-7 ">
                The transit time for the returned package to reach our
                fulfilment centre is generally 8-10 business days. After the
                return is received, replaced product is shipped instantly to the
                customer or a refund may be initiated as per policy.{" "}
              </li>
            </ul>
            <p className="lg:text-lg  pt-8 font-thin tracking-wide opacity-60">
              Orders are only eligible for cancellation if the cancellation
              request is received before the shipping of goods. A cancellation
              request can be made by selecting the same in "My Account" section
              of the customer dashboard. Once a request for cancellation is
              received, the refund will be processed back to the original
              payment method in case of pre-paid orders. Also, replacement can
              only be availed once per order if the above conditions are met.
              Multiple replacement requests for the same order will not be
              entertained.{" "}
            </p>
            {/* <h4 className="sm:text-3xl text-2xl lg:text-4xl text-red-500 pt-16">
              Damaged/Defective Product Policy
            </h4>
            <p className="lg:text-lg  pt-8 font-thin tracking-wide opacity-60">
              All products are guaranteed to be shipped in new condition (except
              used golf balls). If a good arrives damaged due to Dope Golf's or
              its courier partner's negligence, it can be replaced with a new,
              undamaged product. If clearly the outer packaging of the received
              shipment is damaged, it needs to be mentioned on the courier POD
              with the remark "Damaged", otherwise no claims for exchange will
              be entertained. All other issues have to be raised to Customer
              Care. Please contact the customer care team within 24 hours of
              receiving the damaged/defective product. Sufficient images, videos
              and proofs (including the image of address label) need to be
              provided in case outer packaging is damaged. The complete damaged
              product, including all parts and packaging, must be returned.
              Please note that the replacement goods will only be sent after
              Dope Golf receives the damaged product.
            </p> */}
          </div>
        </div>
      </main>
    </div>
  );
};

export default TermsCondition;

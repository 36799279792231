import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import {
  HideIcon,
  StripeIcon,
  SuccessIcon,
  ViewIcon,
} from "../assets/img/index";
import Loader from "../components/Loader";
import authStore from "../stores/auth";
import { ACCESS_TOKEN } from "../utils/constants";
import AddCardForm from "./AddCardForm";
import PaymentOptions from "./PaymentOptions";

const Register = () => {
  const location = useLocation();
  const [step, setStep] = useState(1);
  const [displayModal, setDisplayModal] = useState(false);
  const [paymentSuccessModal, setPaymentSuccessModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [priceId, setPriceId] = useState("");
  const [addCardModal, setAddCardModal] = useState(false);
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [coupon, setCoupon] = useState("");
  const [couponData, setCouponData] = useState("");
  const [selectedAmount, setSelectedAmount] = useState(0);
  const navigate = useNavigate();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY );

  const [showPassword, setShowPassword] = useState(false);
  const [viewPassword, setViewPassword] = useState(false);

  const {
    applyCouponCodeApi,
    updateUserApi,
    setPaymentDataApi,
    getAllPlanApi,
    getAllPlanData,
    addCardData,
    loading,
    getUserDetailAPI,
  } = authStore((state) => ({
    applyCouponCodeApi: state.applyCouponCodeApi,
    updateUserApi: state.updateUserApi,
    setPaymentDataApi: state.setPaymentDataApi,
    getAllPlanData: state.getAllPlanData,
    getAllPlanApi: state.getAllPlanApi,
    addCardData: state.addCardData,
    loading: state.loading,
    getUserDetailAPI: state.getUserDetailAPI,
  }));
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  useEffect(() => {
    try {
      if (
        location?.pathname?.replaceAll("/register/", "") === "s1xuuy5ci7ty8iw9"
      ) {
        setStep(2);
      } else {
        if (
          atob(location?.pathname?.replaceAll("/register/", "")).match(
            validRegex
          ) !== null
        ) {
          setEmail(
            atob(location?.pathname?.replaceAll("/register/", "")) || ""
          );
        } else {
          navigate("/*");
        }
      }
    } catch (error) {
      navigate("/*");
    }
  }, [email]);

  window.onpopstate = () => {
    navigate("/");
  };

  const modal = (
    <>
      <div className="fixed inset-0 z-40 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50"
          // onClick={() => setDisplayModal(true)}
        />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-between px-5 pt-5 pb-2">
                <h1 className="text-themecolor text-center text-xl my-auto">{`Available Card`}</h1>
                <button
                  onClick={() => setAddCardModal(true)}
                  className={`border border-1 text-white border-themecolor px-3 py-2 font-thin rounded-lg text-xs`}
                >
                  {`ADD CARD`}
                </button>
              </div>
              <div className="flex justify-center w-full">
                <div className="w-full px-5">
                  {priceId && (
                    <PaymentOptions
                      coupon={coupon}
                      priceId={priceId}
                      userId={userId}
                      setDisplayModal={setDisplayModal}
                      setPaymentSuccessModal={setPaymentSuccessModal}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const successModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50" />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg">
              <div className="flex justify-end p-5"></div>
              <div className="flex justify-center px-4">
                <div className="text-white">
                  <h1 className="text-tgreen text-center text-xl">{`Payment Successfull!`}</h1>
                  <img
                    src={SuccessIcon}
                    alt=""
                    className="w-20 h-20 p-1 mx-auto my-5"
                  />
                  <h4 className="text-white text-center">{`Your subscription has been successfully activated. You can now download the application by clicking the “Get Application” button below.`}</h4>
                  <div className="flex justify-center space-x-4 my-5">
                    <a
                      href="https://apps.apple.com/us/app/dope-golf/id6503892139"
                      target="_blank"
                      className={`bg-themecolor text-white px-6 py-3 font-thin rounded-lg sm:text-sm text-xs my-auto`}
                      rel="noreferrer"
                    >
                      Get iOS App
                    </a>
                    <a
                      href="https://play.google.com/store/apps/details?id=com.dopegolf.dope"
                      target="_blank"
                      className={`bg-themecolor text-white px-6 py-3 font-thin rounded-lg sm:text-sm text-xs my-auto`}
                      rel="noreferrer"
                    >
                      Get Android App
                    </a>
                    {/* <a
                      href="https://play.google.com/store/search?q=dope%20golf&c=apps"
                      target="_blank"
                      className={`bg-themecolor text-white px-6 py-3 font-thin rounded-lg sm:text-sm text-xs my-auto`}
                      rel="noreferrer"
                    >
                      Get Application
                    </a> */}

                    <button
                      onClick={() => {
                        localStorage.getItem(ACCESS_TOKEN) &&
                          getUserDetailAPI();
                        navigate("/");
                      }}
                      className={`ml-4 border border-1 border-themecolor text-themecolor px-6 py-3 font-thin rounded-lg sm:text-sm text-xs my-auto`}
                    >
                      HOME
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const cardModal = (
    <>
      <div className="fixed inset-0 z-50 overflow-y-auto">
        <div
          className="fixed inset-0 w-full h-full backdrop-blur-sm bg-black/50"
          // onClick={() => setAddCardModal(true)}
        />
        <div className="flex items-center min-h-screen px-4 py-8 cursor-pointer">
          <div className="relative w-full max-w-md mx-auto">
            <div className="bg-[#192035] rounded-lg border border-1 border-themecolor">
              <div className="flex justify-start p-4">
                <h1 className="text-themecolor text-center text-xl">{`Add Card`}</h1>
              </div>
              <div className="flex justify-center px-4">
                <div className="text-white w-full">
                  <h4 className="text-white text-start mb-3">{`Please add your card details for payment`}</h4>
                  <Elements
                    stripe={stripePromise}
                    setAddCardModal={setAddCardModal}
                    userId={userId}
                  >
                    <AddCardForm
                      setAddCardModal={setAddCardModal}
                      userId={userId}
                    />
                  </Elements>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  useEffect(() => {
    setPaymentDataApi();
    step === 2 && getAllPlanApi();
  }, [step, getAllPlanApi]);

  useEffect(() => {
    if (addCardData?.success === true) {
      setAddCardModal(false);
      setSelectedPlan(0);
    }
  }, [addCardData]);

  useEffect(() => {
    if (selectedPlan !== 0) {
      if (selectedPlan === 1) {
        setPriceId(getAllPlanData?.[0]?.stripe_price_id || "");
        setSelectedAmount(getAllPlanData?.[0]?.price);
      } else if (selectedPlan === 2) {
        setPriceId(getAllPlanData?.[1]?.stripe_price_id || "");
        setSelectedAmount(getAllPlanData?.[1]?.price);
      }
    }
  }, [selectedPlan]);

  const clickOnApply = () => {
    selectedPlan === 0
      ? toast.error("First select suscription plan")
      : applyCouponCodeApi({ coupon: coupon }).then(
          (res) =>
            res?.status === 200 && setCouponData(res?.data?.data?.percent_off)
        );
  };

  return (
    <div>
      {addCardModal && cardModal}
      {displayModal && modal}
      {paymentSuccessModal && successModal}
      {/* {loading && <LoaderSmall />} */}
      <main
        className={`${
          step === 1 && `h-screen`
        } flex justify-center pt-[80px] bg-[url('../../assets/img/icon/contact.webp')]`}
      >
        <section className="flex justify-center w-full h-full my-auto contact relative after:content-[''] after:w-full after:absolute after:top-0 after:left-0 after:bg-origin-border after:object-cover after:bg-center after:h-full after:bg-cover after:z-[-1]">
          <div className="container px-2 mx-auto">
            <div className="py-20 max-w-7xl mx-auto relative">
              <div>
                <div>
                  <h2 className="text-white text-3xl xl:text-5xl max-w-md sm:text-3xl md:text-5xl opacity-25 lg:leading-tight xl:leading-tight">
                    {step === 1 ? "Register with" : "Choose your"}
                  </h2>
                  <div className="flex justify-between">
                    <div className="w-2/5">
                      <h2 className="text-white xl:text-4xl py-6 xl:leading-tight sm:text-2xl text-2xl md:text-4xl mb-5">
                        {step === 1 ? "D.O.P.E Golf" : "Subscription plan"}
                      </h2>
                    </div>
                    {step === 1 ? (
                      <div className="py-6 flex">
                        <div className="my-auto flex justify-center rounded-full border-solid border border-1 border-white w-10 h-10 text-center ">
                          <p className="m-auto text-white">1</p>
                        </div>
                        <hr className="w-8 border-gray-100/30 my-auto" />
                        <div className="my-auto flex justify-center rounded-full border-solid border border-1 border-gray-100/30 w-10 h-10 text-center ">
                          <p className="m-auto text-gray-100/30">2</p>
                        </div>
                      </div>
                    ) : (
                      <div className="py-6 flex">
                        <div className="my-auto flex justify-center rounded-full border-solid border border-1 border-gray-100/30 w-10 h-10 text-center ">
                          <p className="m-auto text-gray-100/30">1</p>
                        </div>
                        <hr className="w-8 border-white my-auto" />
                        <div className="my-auto flex justify-center rounded-full border-solid border border-1 border-white w-10 h-10 text-center ">
                          <p className="m-auto text-white">2</p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {step === 1 ? (
                  <>
                    <Formik
                      initialValues={{
                        firstName: "",
                        lastName: "",
                        password: "",
                        c_password: "",
                      }}
                      validationSchema={Yup.object().shape({
                        firstName: Yup.string().required(
                          "First name is required"
                        ),
                        lastName: Yup.string().required(
                          "Last name is required"
                        ),
                        password: Yup.string()
                          .required("Password is required")
                          .min(5, "Password is too short."),
                        c_password: Yup.string()
                          .oneOf(
                            [Yup.ref("password"), null],
                            "Passwords must match"
                          )
                          .required("Confirm password is required"),
                      })}
                      onSubmit={(fields) => {
                        updateUserApi({
                          data: {
                            firstName: fields?.firstName,
                            lastName: fields?.lastName,
                            password: fields?.password,
                          },
                          email: email,
                        }).then(function (res) {
                          if (res?.status === 200) {
                            setUserId(res?.data?.data?.id);
                            localStorage.setItem("userId", res?.data?.data?.id);
                            setStep(2);
                          }
                        });
                      }}
                      render={({ errors, status, touched }) => (
                        <Form className="w-full h-full" autoComplete="off">
                          <p className="text-green-600">{email || ""}</p>
                          {/* {loading && <CustomSpinner />} */}
                          <div className="mx-auto py-14">
                            <div className="flex flex-wrap mb-4">
                              <div className="w-full sm:w-1/2 pr-2">
                                <label
                                  className="text-gray-500 py-2 pt-3 text-xs"
                                  htmlFor="firstName"
                                >
                                  Your First Name
                                </label>
                                <Field
                                  autoComplete="off"
                                  className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                                  type="firstName"
                                  name="firstName"
                                  id="firstName"
                                  placeholder="Enter first name"
                                />
                                <ErrorMessage
                                  name="firstName"
                                  component="div"
                                  className="text-red-500 text-xs"
                                />
                              </div>
                              <div className="w-full sm:w-1/2 sm:mt-0 mt-4">
                                <label
                                  className="text-gray-500 py-2 pt-3 text-xs"
                                  htmlFor="lastName"
                                >
                                  Your Last Name
                                </label>
                                <Field
                                  autoComplete="off"
                                  className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                                  type="lastName"
                                  name="lastName"
                                  id="lastName"
                                  placeholder="Enter last name"
                                />
                                <ErrorMessage
                                  name="lastName"
                                  component="div"
                                  className="text-red-500 text-xs"
                                />
                              </div>
                            </div>
                            <div className="flex flex-wrap mb-4">
                              <div className="w-full sm:w-1/2 pr-2">
                                <label
                                  className="text-gray-500 py-2 pt-3 text-xs"
                                  htmlFor="password"
                                >
                                  Your Password
                                </label>
                                <Field
                                  className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                                  type={viewPassword ? "text" : "password"}
                                  name="password"
                                  id="password"
                                  placeholder="Password"
                                />
                                <div className="relative">
                                  <div className="absolute right-2 top-[-30px] transform -translate-y-1/2">
                                    {viewPassword ? (
                                      <img
                                        src={ViewIcon}
                                        alt="view_icon"
                                        className="h-5 w-5 cursor-pointer"
                                        onClick={() =>
                                          setViewPassword(!viewPassword)
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={HideIcon}
                                        alt="hide_icon"
                                        className="h-5 w-5 cursor-pointer"
                                        onClick={() =>
                                          setViewPassword(!viewPassword)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <ErrorMessage
                                  name="password"
                                  component="div"
                                  className="text-red-500 text-xs"
                                />
                              </div>
                              <div className="w-full sm:w-1/2 sm:mt-0 mt-4">
                                <label
                                  className="text-gray-500 py-2 pt-3 text-xs"
                                  htmlFor="c_password"
                                >
                                  Your Confirm Password
                                </label>
                                <Field
                                  className="block w-full outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 mb-1"
                                  type={showPassword ? "text" : "password"}
                                  name="c_password"
                                  id="c_password"
                                  placeholder="Confirm Password"
                                />
                                <div className="relative">
                                  <div className="absolute right-2 top-[-30px] transform -translate-y-1/2">
                                    {showPassword ? (
                                      <img
                                        src={ViewIcon}
                                        alt="view_icon"
                                        className="h-5 w-5 cursor-pointer"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={HideIcon}
                                        alt="hide_icon"
                                        className="h-5 w-5 cursor-pointer"
                                        onClick={() =>
                                          setShowPassword(!showPassword)
                                        }
                                      />
                                    )}
                                  </div>
                                </div>
                                <ErrorMessage
                                  name="c_password"
                                  component="div"
                                  className="text-red-500 text-xs"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="form-group">
                            <button
                              disabled={loading}
                              className={`${
                                !loading ? `bg-themecolor` : `bg-themecolor/40`
                              } flex px-4 py-2 rounded-lg font-light text-white text-sm mt-5`}
                            >
                              SUBMIT{loading && <Loader />}
                            </button>
                          </div>
                        </Form>
                      )}
                    />
                  </>
                ) : getAllPlanData ? (
                  <>
                    <div className="md:grid md:grid-cols-2 md:gap-8 text-white">
                      <div
                        className="rounded-lg mb-10 text-center plan cursor-pointer"
                        onClick={() => setSelectedPlan(1)}
                      >
                        <h3 className="bg-[#4F5052] py-3 border-2 border-hidden text-base font-thin border-[#FFAE12] tracking-widest rounded-t-lg">
                          {getAllPlanData?.[0]?.title?.toUpperCase() ||
                            "BASIC PLAN"}
                        </h3>
                        <h4 className="text-6xl text-tgreen pt-9">
                          {getAllPlanData?.[0]?.price || "10"}
                          <span className="text-4xl">$</span>
                        </h4>
                        <h5 className="font-thin text-lg">Per month</h5>
                        <p className="font-thin text-base py-6 opacity-50">
                          What you get
                        </p>
                        <ul className="list-disc text-left px-14 text-base grid grid-rows-1 gap-7">
                          <li>
                            The information inputted at onboarding by the
                            introducer
                          </li>
                          <li>
                            Live feed data, from selected 3rd party data sources
                            regarding current air pressure, air temperature,
                            wind speed / direction and location elevation above
                            sea level
                          </li>
                        </ul>
                        <button
                          className={`${
                            selectedPlan === 1
                              ? `bg-white text-themecolor`
                              : `border border-1 border-themecolor`
                          } px-6 py-3 font-thin rounded-lg text-sm mt-16`}
                        >
                          {`${selectedPlan === 1 ? `SELECTED` : `SELECT`}`}
                        </button>
                      </div>

                      <div
                        className="border-2 border-hidden rounded-lg mb-10 text-center border-[#FFAE12] plan hover:rounded-t-lg cursor-pointer"
                        onClick={() => setSelectedPlan(2)}
                      >
                        <h3 className="bg-[#4F5052] py-3 text-base border-2 border-hidden font-thin tracking-widest border-[#FFAE12] rounded-t-lg">
                          {getAllPlanData?.[1]?.title?.toUpperCase() ||
                            "PREMIUM PLAN"}
                        </h3>
                        <h4 className="text-6xl text-tgreen pt-9">
                          {getAllPlanData?.[1]?.price || "15"}
                          <span className="text-4xl">$</span>
                        </h4>
                        <h5 className="font-thin text-lg">Per month</h5>
                        <p className="font-thin text-base py-6 opacity-50">
                          What you get
                        </p>
                        <ul className="list-disc text-left px-14 text-base grid grid-rows-1 gap-7">
                          <li>
                            All the <b>BASIC PLAN</b> features are included:
                          </li>
                          <li>
                            run all of the shot distance calculations for the
                            coming hours
                            <span className="text-tgreen">***</span>
                          </li>
                        </ul>
                        <button
                          className={`${
                            selectedPlan === 2
                              ? `bg-white text-themecolor`
                              : `border border-1 border-themecolor`
                          } px-6 py-3 font-thin rounded-lg text-sm mt-28`}
                        >
                          {`${selectedPlan === 2 ? `SELECTED` : `SELECT`}`}
                        </button>
                      </div>
                    </div>

                    <div className="bg-[#1A1A1C] w-full lg:h-48 h-96 rounded-xl mt-12">
                      <div className="w-full h-full lg:flex">
                        <div className="lg:w-[50%] w-full flex justify-center h-48">
                          <div className="m-auto px-14">
                            <h5 className="opacity-50 font-thin text-lg text-white">
                              {
                                "​If you have received a referral code in your email, please put it here for a discount."
                              }
                            </h5>
                            <div className="bg-[#4F5052] rounded-xl w-full mt-6 h-12 flex justify-between">
                              <input
                                className="pl-3 block outline-none text-white bg-transparent border-0 border-b border-bordergrey/75 py-3 ml-3"
                                type="text"
                                name="fname"
                                id="fname"
                                placeholder="Enter code"
                                onChange={(e) => setCoupon(e.target.value)}
                              />
                              <button
                                disabled={!coupon}
                                onClick={() => clickOnApply()}
                                className={`${
                                  coupon ? `text-themecolor` : `text-[#dfdfdf]`
                                } px-6 py-3 sm:mb-4 text-sm`}
                              >
                                APPLY
                              </button>
                            </div>
                          </div>
                        </div>
                        <hr className="border-gray-100/30 my-auto" />
                        <div className="border-l-2 border-solid border-gray-100/30 md:h-0 sm:h-0 h-0 lg:h-32 my-auto"></div>
                        <div className="lg:w-[50%] w-full flex justify-center h-48">
                          <div className="flex m-auto xl:px-14 lg:px-8 md:px-14 sm:px-14 px-4 w-full">
                            <div className="w-[50%] flex flex-col justify-between">
                              <div className="my-auto">
                                <div className="flex justify-between font-thin sm:text-lg text-sm">
                                  <h5 className="text-white">Gross Amount</h5>
                                  <h5 className="text-white sm:ml-22 ml-12">
                                    <>${selectedAmount}</>
                                  </h5>
                                </div>
                              </div>
                              {couponData && (
                                <div className="flex justify-between font-thin sm:text-lg text-sm">
                                  <h5 className="text-green-600">
                                    Promo Discount
                                  </h5>
                                  <h5 className="text-green-600 sm:ml-20 ml-10">
                                    {couponData
                                      ? `-$${
                                          (Number(selectedAmount) *
                                            Number(couponData)) /
                                          100
                                        }`
                                      : "-"}
                                  </h5>
                                </div>
                              )}

                              <div className="mt-4">
                                <div className="flex justify-between font-thin sm:text-xl text-md my-auto">
                                  <h5 className="text-white opacity-50">
                                    Total Payble
                                  </h5>
                                  <h5 className="text-white sm:ml-24 ml-12">
                                    {couponData ? (
                                      <>
                                        $
                                        {selectedAmount -
                                          (Number(selectedAmount) *
                                            Number(couponData)) /
                                            100}
                                      </>
                                    ) : (
                                      <>${selectedAmount}</>
                                    )}
                                  </h5>
                                </div>
                              </div>
                            </div>
                            <div className="w-[50%] justify-end items-end flex flex-col">
                              <img alt="" src={StripeIcon} />
                              <button
                                disabled={!(priceId && !loading)}
                                className={`${
                                  priceId && !loading
                                    ? `bg-themecolor`
                                    : `bg-themecolor/40`
                                } flex text-white px-6 py-3 mt-4 font-thin rounded-lg sm:text-sm text-xs my-auto`}
                                onClick={() => {
                                  setDisplayModal(true);
                                }}
                              >
                                CONTINUE{loading && <Loader />}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h4 className="text-6xl text-red-500 pt-9">
                      {"No plan Available"}
                    </h4>
                  </>
                )}
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Register;

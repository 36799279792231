import { useElements, useStripe } from "@stripe/react-stripe-js";
import React, { memo, useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import authStore from "../stores/auth";

const CheckoutForm = ({
  setDisplayModal,
  setPaymentSuccessModal,
  priceId,
  userId,
  coupon,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [selectedCard, setSelectedCard] = useState();

  const {
    paymentStatusApi,
    userCardListApi,
    userCardData,
    setConfirmPaymentDataApi,
    addCardData,
    loadingAPI,
    userCardLoading,
  } = authStore((state) => ({
    paymentStatusApi: state.paymentStatusApi,
    userCardListApi: state.userCardListApi,
    userCardData: state.userCardData,
    setConfirmPaymentDataApi: state.setConfirmPaymentDataApi,
    addCardData: state.addCardData,
    loadingAPI: state.loading,
    userCardLoading: state.userCardLoading,
  }));

  useEffect(() => {
    userCardListApi({
      userId: userId,
    });
    setSelectedCard();
  }, [userCardListApi, addCardData]);

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setloading] = useState(false);

  const handleSubmit = async (event) => {
    if (!stripe || !elements) {
      return;
    }
    paymentStatusApi({
      userId: userId,
      priceId: priceId,
      coupon: coupon,
    }).then((res) => {
      if (res?.status === 200 && res?.data?.data) {
        const { client_secret, confirm_card_payment } = res.data.data;
        if (confirm_card_payment === false) {
          setDisplayModal(false);
          setPaymentSuccessModal(true);
          setConfirmPaymentDataApi({});
          setloading(false);
        } else if (client_secret) {
          stripe
            .confirmCardPayment(res?.data?.data, {
              payment_method: selectedCard?.card?.id,
            })
            .then((resPayment) => {
              if (resPayment?.paymentIntent?.id) {
                setDisplayModal(false);
                setPaymentSuccessModal(true);
                setConfirmPaymentDataApi(resPayment?.paymentIntent);
                setloading(false);
              } else {
                setErrorMessage(resPayment.message);
                setloading(false);
              }
            });
        }
      } else {
        setloading(false);
        toast.error("Somthing went wrong.");
        setDisplayModal(false);
      }
    });
  };

  return (
    <>
      <div className="w-full text-white">
        {userCardLoading && (
          <div className="flex w-full justify-center bg-gray-700 opacity-75 p-10 rounded-lg">
            <Loader />
          </div>
        )}
        {userCardData?.data?.length === 0 && (
          <p className="text-center my-5">No Card Available, Please add card</p>
        )}

        {userCardData?.data?.map((item, index) => (
          <div
            onClick={() => setSelectedCard(item)}
            key={index}
            className={`${
              item === selectedCard
                ? `bg-themecolor text-white`
                : `bg-slate-300 text-black`
            } mt-3 flex justify-between p-3 border-2 border-hidden text-base font-thin rounded-lg`}
          >
            <div>{`**** **** **** ${item?.card?.card?.last4}`}</div>
            <div className="flex my-auto">
              <div>{`${item?.card?.card?.brand?.toUpperCase()}`}</div>
              <div className={`w-5 h-5 rounded-full bg-black my-auto ml-5`}>
                <div
                  className={`${
                    item === selectedCard ? `bg-themecolor` : `bg-slate-300`
                  } w-3 h-3 m-1 flex justify-center rounded-full`}
                ></div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <p>{errorMessage}</p>
      <div className="flex justify-end items-center mt-2">
        <button
          disabled={loading}
          type="submit"
          className={`bg-transparent border border-1 border-slate-500 flex px-4 py-2 rounded-lg font-light text-slate-500 text-sm my-3`}
          onClick={() => setDisplayModal(false)}
        >
          CANCEL
        </button>
        <button
          type="submit"
          disabled={
            !stripe || !elements || loading || loadingAPI || !selectedCard
          }
          className={`${
            !loading && selectedCard ? `bg-themecolor` : `bg-themecolor/40`
          } ml-3 flex px-4 py-2 rounded-lg font-light text-white text-sm my-5`}
          onClick={() => {
            setloading(true);
            handleSubmit();
          }}
        >
          PAY NOW{loading && <Loader />}
        </button>
      </div>
    </>
  );
};

export default memo(CheckoutForm);

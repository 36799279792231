import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const IntroducerHeader = ({ display, setDisplay }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <>
      <header className="fixed sm:top-[60px] top-[40px] left-0 right-0 bg-slate-400 text-white z-40 pt-0">
        <nav className="flex flex-wrap justify-center items-center">
          <div className="menu">
            <div className="flex flex-wrap items-center">
              <div className="group relative">
                <ul className="relative top-full right-0 flex bg-transparent ">
                  <li className="cursor-pointer sm:px-8 px-2 py-2 md:text-base sm:text-sm text-xs">
                    <div
                      className={`${
                        location?.pathname === "/introducer" && `text-red-600`
                      }`}
                      onClick={() => navigate("/introducer")}
                    >
                      Overview
                    </div>
                  </li>

                  <li className="cursor-pointer sm:px-8 px-2 py-2 md:text-base sm:text-sm text-xs">
                    <div
                      className={`${
                        location?.pathname?.includes(
                          "/introducer/become-introducer"
                        ) && `text-red-600`
                      }`}
                      onClick={() => navigate("/introducer/become-introducer")}
                    >
                      Become an Introducer
                    </div>
                  </li>

                  {/* <li className="cursor-pointer sm:px-8 px-2 py-2 md:text-base sm:text-sm text-xs">
                    <div
                      className={`${
                        location?.pathname?.includes("annual-introducer") &&
                        `text-red-600`
                      }`}
                      onClick={() => navigate("/introducer/annual-introducer")}
                    >
                      Annual Introducer Competition
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default IntroducerHeader;
